(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('contact-us', {
            parent: 'app',
            url: '/contact-us',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/contact-us/contact-us.html',
                    controller: 'ContactUsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact-us');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('contacts', {
            parent: 'app',
            url: '/contacts',
            data: {
                authorities: [],
                pageTitle: 'contacts'
            },
            views: {
                'content@': {
                    templateUrl: 'app/contact-us/contacts.html',
                    controller: 'ContactUsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact-us');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('sp', {
            parent: 'app',
            url: '/sp',
            data: {
                authorities: [],
                pageTitle: 'contacts'
            },
            views: {
                'content@': {
                    templateUrl: 'app/contact-us/sp.html',
                    controller: 'ContactUsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact-us');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('po', {
            parent: 'app',
            url: '/po',
            data: {
                authorities: [],
                pageTitle: 'contacts'
            },
            views: {
                'content@': {
                    templateUrl: 'app/contact-us/po.html',
                    controller: 'ContactUsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact-us');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('dpo', {
            parent: 'app',
            url: '/dpo',
            data: {
                authorities: [],
                pageTitle: 'contacts'
            },
            views: {
                'content@': {
                    templateUrl: 'app/contact-us/dpo.html',
                    controller: 'ContactUsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact-us');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('dcpo', {
            parent: 'app',
            url: '/dcpo',
            data: {
                authorities: [],
                pageTitle: 'contacts'
            },
            views: {
                'content@': {
                    templateUrl: 'app/contact-us/dcpo.html',
                    controller: 'ContactUsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact-us');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('chairpersons', {
            parent: 'app',
            url: '/chairpersons',
            data: {
                authorities: [],
                pageTitle: 'contacts'
            },
            views: {
                'content@': {
                    templateUrl: 'app/contact-us/chairpersons.html',
                    controller: 'ContactUsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact-us');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('vicechairpersons', {
            parent: 'app',
            url: '/vicechairpersons',
            data: {
                authorities: [],
                pageTitle: 'contacts'
            },
            views: {
                'content@': {
                    templateUrl: 'app/contact-us/vicechairpersons.html',
                    controller: 'ContactUsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact-us');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('wps', {
            parent: 'app',
            url: '/wps',
            data: {
                authorities: [],
                pageTitle: 'contacts'
            },
            views: {
                'content@': {
                    templateUrl: 'app/contact-us/wps.html',
                    controller: 'ContactUsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact-us');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
