(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('complaint-form', {
            parent: 'entity',
            url: '/complaint-form',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'womenCellApp.complaintForm.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/complaint-form/complaint-forms.html',
                    controller: 'ComplaintFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('complaintForm');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('complaint-form-detail', {
            parent: 'complaint-form',
            url: '/complaint-form/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'womenCellApp.complaintForm.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/complaint-form/complaint-form-detail.html',
                    controller: 'ComplaintFormDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('complaintForm');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ComplaintForm', function($stateParams, ComplaintForm) {
                    return ComplaintForm.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'complaint-form',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('complaint-form-detail.edit', {
            parent: 'complaint-form-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/complaint-form/complaint-form-dialog.html',
                    controller: 'ComplaintFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ComplaintForm', function(ComplaintForm) {
                            return ComplaintForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('complaint-form.new', {
            parent: 'complaint-form',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/complaint-form/complaint-form-dialog.html',
                    controller: 'ComplaintFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                cName: null,
                                cAddress: null,
                                cDistrict: null,
                                cPin: null,
                                cEmail: null,
                                cMobile: null,
                                cLandline: null,
                                cSex: null,
                                complainantAsVictim: null,
                                vName: null,
                                vAddress: null,
                                vDistrict: null,
                                vPin: null,
                                vEmail: null,
                                vLandline: null,
                                vMobile: null,
                                vSex: null,
                                vReligion: null,
                                vCaste: null,
                                accusedName: null,
                                accusedAddress: null,
                                accusedDistrict: null,
                                accusedPin: null,
                                accusedEmail: null,
                                accusedMobile: null,
                                accusedLandline: null,
                                accusedSex: null,
                                complaintDate: null,
                                complaintType: null,
                                incidentDetail: null,
                                policeComplaintRegistrationStatus: null,
                                caseFiledInCourtStatus: null,
                                expectedActionFromCommission: null,
                                policeComplaintFileName: null,
                                caseInCourtFileName: null,
                                attachedFileName: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('complaint-form', null, { reload: 'complaint-form' });
                }, function() {
                    $state.go('complaint-form');
                });
            }]
        })
        .state('complaint-form.edit', {
            parent: 'complaint-form',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/complaint-form/complaint-form-dialog.html',
                    controller: 'ComplaintFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ComplaintForm', function(ComplaintForm) {
                            return ComplaintForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('complaint-form', null, { reload: 'complaint-form' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('complaint-form.delete', {
            parent: 'complaint-form',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/complaint-form/complaint-form-delete-dialog.html',
                    controller: 'ComplaintFormDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ComplaintForm', function(ComplaintForm) {
                            return ComplaintForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('complaint-form', null, { reload: 'complaint-form' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
