(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('SuggestionController', SuggestionController);

    SuggestionController.$inject = ['Suggestion'];

    function SuggestionController(Suggestion) {

        var vm = this;

        vm.suggestions = [];

        loadAll();

        function loadAll() {
            Suggestion.query(function(result) {
                vm.suggestions = result;
                vm.searchQuery = null;
            });
        }
    }
})();
