(function() {
    'use strict';

    angular
    .module('womenCellApp')
    .factory('ComplaintDownloadPdf', ['$q', '$timeout', '$window',
        function($q, $timeout, $window, $http) {
            return {
                download: function(id) {
                    var defer = $q.defer();
                    $timeout(function() {
                       $window.location = 'api/downloadPdf?id=' + id;}
                        , 1000)
                        .then(function() {
                            defer.resolve('success');
                        }, function() {
                            defer.reject('error');
                        });
                    return defer.promise;
                }
            };
        }
    ]);
})();
