(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('PicGalleryFolderController', PicGalleryFolderController);

    PicGalleryFolderController.$inject = ['$scope','ImgSave','Gallery','ImgDel', 'CheckContent'];

    function PicGalleryFolderController($scope, ImgSave, Gallery,ImgDel, CheckContent) {
        var vm = this;
        vm.upload=upload;
        vm.confirmDelete=confirmDelete;
        vm.gallery={};
        vm.galleryList=[];
        vm.del=false;
        vm.imageVal=null;
        vm.delIcon=delIcon;

        Gallery.query({toGet:"totalCaptions",type:"gallery"},function(result){
            vm.folderList=result;
            if(vm.folderList.length==0){
                $scope.nofile=true;
            }else $scope.nofile=false;
        });

        function delIcon() {
            vm.del = !vm.del;
        }

        function upload(){
            if(CheckContent.checkFile(vm.image.name)){
                Gallery.query({toGet:vm.photo.caption,type:"gallery_"},function(result){
                    vm.folderCheck=result;
                    if(vm.folderCheck.length==0) {
                        ImgSave.upload(vm.image, function(result){
                            saveEntity(result);
                        });
                    }else{
                        alert('Caption already exist, choose different caption');
                    }
                });
            }else alert('Invalid file format!\n' +
                'अमान्य फ़ाइल प्रकार!');
        }

        function saveEntity(result) {
            vm.gallery.filename=result.filename;
            vm.gallery.type="gallery";
            vm.gallery.caption=vm.photo.caption;
            vm.gallery.fileType="Image";
            Gallery.save(vm.gallery,onSaveSuccess,onSaveError);
        }

        function onSaveSuccess() {
            ImgSave.upload(vm.image, function(result){
              vm.gallery.filename=result.filename;
              vm.gallery.description=vm.photo.caption;
              vm.gallery.caption=null;
              Gallery.save(vm.gallery,function(){
                   alert("Successfully added album!");
                   location.reload();
                });
            });
        }

        function onSaveError() {
            alert("Error while adding image in gallery! Please try again after some time.");
            location.reload();
        }

        function confirmDelete(image) {
            ImgDel.delete({filename: image.filename},function(){
                Gallery.delete({id: image.id},onSuccessDel(image));
            });
        }

        function onSuccessDel(result){
            Gallery.query({toGet:result.caption,type:"gallery"},function(result){
                vm.galleryList=result;
                for(var i=0;i<vm.galleryList.length;i++)
                {
                    var id=vm.galleryList[i].id;
                   if(vm.galleryList[i].fileType==="Image")
                     {
                       ImgDel.delete({filename: vm.galleryList[i].filename});
                       Gallery.delete({id: id});
                     }else{
                         Gallery.delete({id: id});
                     }
                }
                alert("Successfully deleted the album!");
                location.reload();
            });
        }

        $scope.setValue=function(val){
         vm.imageVal=val;
        }

    }
})();
