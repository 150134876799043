(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('ContactUsController', ContactUsController);

    ContactUsController.$inject = ['$scope'];

    function ContactUsController($scope) {
        var vm = this;

    }
})();
