(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('UpdateSoonController', UpdateSoonController);

    UpdateSoonController.$inject = ['$scope','$stateParams'];

    function UpdateSoonController($scope,$stateParams) {
        var vm = this;
        vm.news1=false;
        vm.news2=false;
        vm.news3=false;
        loadAll();

        function loadAll()
        {
            if($stateParams.value=="news1")
            {
                vm.news1=true;
            }
            if($stateParams.value=="news2")
            {
                vm.news2=true;
            }
            if($stateParams.value=="news3")
            {
                vm.news3=true;
            }

        }

    }
})();
