(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .factory('ImgDel',['$resource',

      function ImgDel($resource){

        var resourceUrl =  'api/ImgDel/:filename';

        return $resource(resourceUrl, {}, {
           'delete': {
                   method: 'DELETE',
                   transformResponse: function (data) {
                       if (data) {
                           data = angular.fromJson(data);
                       }
                       return data;
                   }
               }
           });
    }]);
})();
