(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('ComplaintFormDialogController', ComplaintFormDialogController);

    ComplaintFormDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ComplaintForm'];

    function ComplaintFormDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ComplaintForm) {
        var vm = this;

        vm.complaintForm = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.complaintForm.id !== null) {
                ComplaintForm.update(vm.complaintForm, onSaveSuccess, onSaveError);
            } else {
                ComplaintForm.save(vm.complaintForm, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('womenCellApp:complaintFormUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.complaintDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
