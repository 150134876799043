(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .factory('FileSave',['$http','$state', function($http,$state){
        return {
            upload: function(file,callit){
            var resourceUrl =  'api/FileSave';
            var fd = new FormData();
            fd.append('file', file);
            return $http.post(resourceUrl, fd, {
            transformRequest : angular.identity,
            headers : {
            'Content-Type' : undefined
            }
            }).success(function(result){
                callit(result);
            }).error(function(result){
              alert("Error while uploading your files!");
              $state.go('home');
              location.reload();
            });
            }
        }
    }]);
})();
