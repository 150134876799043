(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal', '$state'];

    function PasswordController (Auth, Principal, $state) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorOldNotMatch = false;
        vm.oldPassword = null;
        vm.success = null;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.checkPassword({
                    username: vm.account.login,
                    password: new AesUtil().encryptPassword(vm.oldPassword)
                },function(result){
                    if(result){
                        vm.errorOldNotMatch = false;
                        vm.doNotMatch = null;
                        Auth.changePassword( new AesUtil().encryptPassword(vm.password)).then(function () {
                            vm.error = null;
                            vm.success = 'OK';
                            alert('Password Change Successfully');
                            $state.reload('password');
                        }).catch(function () {
                            vm.success = null;
                            vm.error = 'ERROR';
                        });
                    }else vm.errorOldNotMatch = true;
                });
            }
        }
    }
})();
