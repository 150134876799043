(function() {
    'use strict';
    angular
        .module('womenCellApp')
        .factory('HomeService', HomeService);

    HomeService.$inject = ['$resource'];

    function HomeService ($resource) {
        var resourceUrl =  'api/defaultImageDel/:filename';

        return $resource(resourceUrl, {}, {
            'delete': {
               method: 'DELETE',
               transformResponse: function (data) {
                   if (data) {
                       data = angular.fromJson(data);
                   }
                   return data;
               }
             }
        });
    }
})();
