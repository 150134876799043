(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('SitemapController', SitemapController);

    SitemapController.$inject = ['$scope'];

    function SitemapController($scope) {
        var vm = this;


    }
})();
