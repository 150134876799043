(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state','DefaultImgSave','HomeService'];

    function HomeController ($scope, Principal, LoginService, $state, DefaultImgSave, HomeService) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.del=false;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });
        $scope.name="IY";
        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        $scope.upload=function(){
        //Filename added from here is of without extension
            DefaultImgSave.upload(vm.image, function(result){
                alert("Uploaded successfully");
            });
        };

        $scope.setDeleteVal=function(){
            if(vm.del==false) {
                vm.del=true;
             }else{
                vm.del=false;
             }
        };
        $scope.deletePhoto=function(filename){
            HomeService.delete({filename: filename},function(result){
                if(result.filename==("true")){
                   alert("Image deleted successfully!");
                }else{
                   alert("Error while deleting image!")
                }
            });
        }
    }
})();
