(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('ViewSuggestionController', ViewSuggestionController);

    ViewSuggestionController.$inject = ['$scope','Suggestion'];

    function ViewSuggestionController($scope,Suggestion) {
        var vm = this;

        Suggestion.query(function(result) {
            vm.suggestions = result;
        });
    }
})();
