(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('AboutUsController', AboutUsController);

    AboutUsController.$inject = ['$scope'];

    function AboutUsController($scope) {
        var vm = this;



    }
})();
