(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('GalleryController', GalleryController);

    GalleryController.$inject = ['Gallery'];

    function GalleryController(Gallery) {

        var vm = this;

        vm.galleries = [];

        loadAll();

        function loadAll() {
            Gallery.query(function(result) {
                vm.galleries = result;
                vm.searchQuery = null;
            });
        }
    }
})();
