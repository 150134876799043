(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('ComplaintFormController', ComplaintFormController);

    ComplaintFormController.$inject = ['ComplaintForm'];

    function ComplaintFormController(ComplaintForm) {

        var vm = this;

        vm.complaintForms = [];

        loadAll();

        function loadAll() {
            ComplaintForm.query(function(result) {
                vm.complaintForms = result;
                vm.searchQuery = null;
            });
        }
    }
})();
