(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('SuggestionsController', SuggestionsController);

    SuggestionsController.$inject = ['$scope','Suggestion', 'CheckContent'];

    function SuggestionsController($scope,Suggestion, CheckContent) {
        var vm = this;
        vm.success = null;
        vm.error = null;
        vm.save=save;
        vm.suggestions = ["Related To ACTs","Complaint Form","Online Support","Other"];

        function save () {
            if(vm.suggestion.suggestionType=='Other'){
                vm.suggestion.suggestionType=vm.suggestion.other;
            }else{
               vm.suggestion.suggestionType=vm.suggestion.suggestionType;
            }
            Suggestion.save(vm.suggestion, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.success = 'OK';
        }

        function onSaveError () {
            vm.error = 'ERROR';
        }

        $('input').each(function(){ CheckContent.checkInput($(this))});
        $('textarea').each(function() { CheckContent.checkInput($(this))});
    }
})();
