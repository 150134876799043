(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('JoinUsController', JoinUsController);

    JoinUsController.$inject = ['$scope','$filter','$sce','Gallery'];

    function JoinUsController($scope,$filter,$sce,Gallery) {
        var vm = this;
        vm.getImageUrl=getImageUrl;
        vm.joinUs=[];
        vm.newsList=[];
        vm.saveEntity=saveEntity;
        vm.del=false;
        vm.delIcon=delIcon;
        vm.removeImg=removeImg;
        $scope.selection=[];

        Gallery.query({id:"all",type:"joinUs"},function(result){
          vm.newsList=result;   //all video type files

          if(vm.newsList.length==0)
          {
              $scope.nofile=true;
          }else {
              $scope.nofile=false;
              vm.src1= $sce.trustAsResourceUrl("https://www.youtube.com/embed/"+vm.newsList[0].filename);
              vm.src2= $sce.trustAsResourceUrl("https://www.youtube.com/embed/"+vm.newsList[1].filename);
              vm.src3= $sce.trustAsResourceUrl("https://www.youtube.com/embed/"+vm.newsList[2].filename);
              $scope.toggle1=vm.newsList[0].id+"_"+vm.newsList[0].filename;
              $scope.toggle2=vm.newsList[1].id+"_"+vm.newsList[1].filename;
              $scope.toggle3=vm.newsList[2].id+"_"+vm.newsList[2].filename;
          }
        });

        function delIcon()
        {
           if(vm.del)
           {
               vm.del=false;
           }else vm.del=true;
        }


        function getImageUrl(image) {
          if(image.fileType==="Video"){
              return $sce.trustAsResourceUrl("https://www.youtube.com/embed/"+image.filename);
          }
        }
        function saveEntity() {
          vm.gallery.type="joinUs";
          vm.gallery.fileType="Video";
          Gallery.save(vm.gallery,onSaveSuccess,onSaveError);
        }
        function onSaveSuccess()
        {
           alert("Successfully added file!");
           location.reload();
        }
        function onSaveError()
        {
           alert("Error while adding file. Please try again after some time!");
           location.reload();
        }

        $scope.toggleSelection=function(twoValue){
          var index=$scope.selection.indexOf(twoValue);
          if(index > -1)
          {
              $scope.selection.splice(index,1);
          }else{
             $scope.selection.push(twoValue);
          }
        }
        function removeImg()
        {
           for(var i=0;i<$scope.selection.length;i++)
           {
             var id=$scope.selection[i].id;
             var filename=$scope.selection[i].filename;
             confDel($scope.selection[i].fileType,id,filename);
           }
        }
        function confDel(type,id,filename)
        {
           Gallery.delete({id: id},onDelSuccess,onDelError);
        }
        function onDelSuccess()
        {
          alert("File is deleted successfully!");
          location.reload();
        }
        function onDelError()
        {
          alert("Error while deleting the file. Please try again after some time!");
          location.reload();
        }
    }
})();
