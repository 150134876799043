(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .factory('CheckContent', CheckContent);

    CheckContent.$inject = [];

    function CheckContent () {
        var service = {
            checkFile: checkFile,
            checkInput : checkInput,
            getExtension: getExtension
        };

        return service;

        function checkFile(fileName) {
            if(fileName!=null){
                var splitArr = fileName.split(".");
                if(splitArr[0]!=""){
                    return splitArr.length == 2;
                }
            } return false;
        }

        function getExtension(fileName) {
            if(fileName!=null){
                var splitArr = fileName.split(".");
                if(splitArr[0]!=""){
                    if(splitArr.length == 2) {
                        return splitArr[1];
                    }else return false
                }else return false;
            } return false;
        }

        function checkInput (elem) {
            elem.bind("propertychange change click keyup input paste", function(){
                if(elem.val().includes('<script') || elem.val().includes('alert(') || elem.val().includes('.js') || elem.val().includes('.exe')
                    || elem.val().includes('.sh') || elem.val().includes('.jar') || elem.val().includes('.class') || elem.val().includes('.class')
                    || elem.val().includes('bash') || elem.val().includes('./') || elem.val().includes('.tar.gz') || elem.val().includes('.deb')
                    || elem.val().includes('.rpm') || elem.val().includes('.ko') || elem.val().includes('.php') || elem.val().includes('.py')
                    || elem.val().includes('.tar')){
                    elem.val('');
                    alert('Please insert relevant information in this field! \n' +
                        'कृपया इस क्षेत्र में वैध जानकारी डालें!');
                }
            });
        }
    }
})();
