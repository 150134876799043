(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .factory('ImgSave',['$http', function($http){
        return {
            upload: function(file,callit){
            var resourceUrl =  'api/ImgSave';
            var fd = new FormData();
            fd.append('file', file);
            return $http.post(resourceUrl, fd, {
            transformRequest : angular.identity,
            headers : {
            'Content-Type' : undefined
            }
            }).success(function(result){
                callit(result);
            }).error(function(){
                alert("Error while saving your image. File should not be more than 5MB!");
                location.reload();
            });
            }
        }
    }]);
})();
