(function() {
    'use strict';
    angular
        .module('womenCellApp')
        .factory('ComplaintForm', ComplaintForm);

    ComplaintForm.$inject = ['$resource', 'DateUtils'];

    function ComplaintForm ($resource, DateUtils) {
        var resourceUrl =  'api/complaint-forms/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.complaintDate = DateUtils.convertDateTimeFromServer(data.complaintDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
