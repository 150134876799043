(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('InterviewController', InterviewController);

    InterviewController.$inject = ['$stateParams', '$sce','$scope','ImgSave','Gallery','ImgDel', 'CheckContent'];

    function InterviewController($stateParams, $sce,$scope, ImgSave, Gallery,ImgDel, CheckContent) {
        var vm = this;
        vm.upload=upload;
        vm.uploadCover=uploadCover;
        vm.gallery={};
        vm.gallery.fileType="Image";
        vm.filenameList=[];
        vm.removeImg=removeImg;
        vm.del=false;
        vm.mov=false;
        vm.delIcon=delIcon;
        vm.confirmMove=confirmMove;
        vm.moveIcon=moveIcon;
        $scope.selection=[];
        vm.caption=$stateParams.caption;
        vm.moveTo=null;

        Gallery.query({toGet:vm.caption,type:"interview"},function(result){
            vm.galleryList=result;
            if(vm.galleryList.length==0) {
                $scope.nofile=true;
            }else $scope.nofile=false;
        });
        Gallery.query({toGet:"totalCaptions",type:"interview"},function(result){
            vm.folderList=result;
        });

        function delIcon()
        {
            if(vm.del)
            {
                vm.del=false;
            }else vm.del=true;
        }
        function moveIcon()
        {
            if(vm.mov)
            {
                vm.mov=false;
            }else vm.mov=true;
        }

        function upload(){
            if(vm.image==null){
                saveEntity("Video")
            }
            else{
                if(vm.image.length>1) {
                    for(var i=0;i<vm.image.length;i++) {
                        if(CheckContent.checkFile(vm.image[i].name)){
                            ImgSave.upload(vm.image[i], function(result){
                                saveEntity(result);
                            });
                        }else alert('Invalid file format!\n' +
                            'अमान्य फ़ाइल प्रकार!');
                    }
                }
                else{
                    if(CheckContent.checkFile(vm.image.name)){
                        ImgSave.upload(vm.image, function(result){
                            saveEntity(result);
                        });
                    }else alert('Invalid file format!\n' +
                        'अमान्य फ़ाइल प्रकार!');
                }
            }
        }
        function saveEntity(result) {
            if(result==="Video"){
                vm.gallery.filename=vm.filename;
            }
            else {
                vm.gallery.filename=result.filename;
            }
            vm.gallery.type="interview";
            vm.gallery.description=vm.caption;
            Gallery.save(vm.gallery,onSaveSuccess,onSaveError);
        }
        function onSaveSuccess()
        {
            alert("Successfully added file!");
            location.reload();

        }
        function onSaveError()
        {
            alert("Error while adding. Please try again after some time!");
            location.reload();
        }

        $scope.toggleSelection=function(twoValue){
            var index=$scope.selection.indexOf(twoValue);
            if(index > -1)
            {
                $scope.selection.splice(index,1);
            }else{
               $scope.selection.push(twoValue);
            }
        }
        function removeImg()
        {
            for(var i=0;i<$scope.selection.length;i++)
            {
              var id=$scope.selection[i].id;
              var filename=$scope.selection[i].filename;
              confDel($scope.selection[i].fileType,id,filename);
            }
        }
        function confDel(type,id,filename)
        {
          if(type==="Image")
          {
            ImgDel.delete({filename: filename},function(){
              Gallery.delete({id: id},onDelSuccess,onDelError);
            });
          }else{
              Gallery.delete({id: id},onDelSuccess,onDelError);
           }
         }
        function onDelSuccess()
        {
            alert("File is deleted successfully!");
            location.reload();
        }
        function onDelError()
        {
            alert("Error while deleting the file. Please try again after some time!");
            location.reload();
        }
        function confirmMove()
        {
           for(var i=0;i<$scope.selection.length;i++)
           {
             $scope.selection[i].description=vm.moveTo;
             Gallery.update($scope.selection[i],onMoveSuccess,onMoveFail);
           }
        }
        function onMoveSuccess()
        {
           alert("Successfully moved!");
           location.reload();
        }
        function onMoveFail()
        {
           alert("Error while moving the file! Please try again after sometime.");
           location.reload();
        }
        function uploadCover(){
            Gallery.query({toGet:vm.photo.caption,type:"interview_"},function(result){
                vm.folderCheck=result;
                if(vm.folderCheck.length==0) {
                    ImgSave.upload(vm.image, function(result){
                        saveEntityCover(result);
                    });
                }else{
                    alert('Caption already exist, choose different caption');
                }
            });
        }
        function saveEntityCover(result)
        {
            vm.gallery.filename=result.filename;
            vm.gallery.type="interview";
            vm.gallery.caption=vm.photo.caption;
            vm.gallery.fileType="Image";
            vm.moveTo=vm.gallery.caption;
            Gallery.save(vm.gallery,onSaveCaptionSuccess,onSaveError);
        }
        function onSaveCaptionSuccess()
        {
            ImgSave.upload(vm.image, function(result){
              vm.gallery.filename=result.filename;
              vm.gallery.description=vm.photo.caption;
              vm.gallery.caption=null;
              Gallery.save(vm.gallery,function(){
                   confirmMove();
                });
            });
        }
        vm.getImageUrl=function (image) {
            if(image.fileType==="Video"){
                return $sce.trustAsResourceUrl("https://www.youtube.com/embed/"+image.filename);
            }
        }
    }
})();
