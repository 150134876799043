(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('pressnote', {
            parent: 'app',
            url: '/pressnote',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/press-note/press-note.html',
                    controller: 'PressNoteController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('news');
                    return $translate.refresh();
                }]
            }
        })
        .state('press-note-view', {
            parent: 'pressnote',
            url: '/press-note-view/{filename}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/press-note/press-note-view.html',
                    controller: 'PressNoteViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    keyboard: false,
                    resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('news');
                        return $translate.refresh();
                    }]
                    }
                }).result.then(function() {
                    $state.go('pressnote', null, {});
                }, function() {
                    $state.go('pressnote');
                });
            }]
        });
      }
})();
