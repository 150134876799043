(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('ViewSuggestionDetailController', ViewSuggestionDetailController);

    ViewSuggestionDetailController.$inject = ['$scope', 'entity','$timeout'];

    function ViewSuggestionDetailController($scope,entity,$timeout) {
        var vm = this;
        vm.suggestion=entity;
        vm.printToCart=printToCart;

        function printToCart(printSectionId) {
            var innerContents = document.getElementById(printSectionId).innerHTML;
            var popupWinindow = window.open('', '_blank', 'width=1100,height=700');
            popupWinindow.document.open();
            popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
            $timeout(function() {
            popupWinindow.document.close();
            },100);
        }

    }
})();
