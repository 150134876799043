(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('InterviewViewController', InterviewViewController);

    InterviewViewController.$inject = ['$scope','$stateParams','$uibModalInstance'];

    function InterviewViewController($scope,$stateParams, $uibModalInstance) {
        var vm = this;
        $scope.filename=$stateParams.filename;
        vm.closeView=closeView;

        function closeView()
        {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
