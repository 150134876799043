(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('view-complaint', {
            parent: 'app',
            url: '/view-complaint',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_HSCW']
            },
            views: {
                'content@': {
                    templateUrl: 'app/view-complaint/view-complaint.html',
                    controller: 'ViewComplaintController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('view-complaint');
                    return $translate.refresh();
                }]
            }
        })
        .state('view-complaint-detail', {
            parent: 'view-complaint',
            url: '/view-complaint/{id}',
            data: {
                    authorities: ['ROLE_ADMIN','ROLE_HSCW']
                },
            views: {
                'content@': {
                    templateUrl: 'app/view-complaint/viewComplaintDetail.html',
                    controller: 'ViewComplaintDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('view-complaint-detail');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ComplaintForm', function($stateParams, ComplaintForm) {
                    return ComplaintForm.get({id : $stateParams.id}).$promise;
                }]
            }
        });
    }
})();
