(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('interview', {
            parent: 'app',
            url: '/interview/album/{caption}',
            data: {
                authorities: [],
                pageTitle: 'global.menu.gallery'
            },
            views: {
                'content@': {
                    templateUrl: 'app/interview/interview.html',
                    controller: 'InterviewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('news');
                    return $translate.refresh();
                }]
            }
        })
        .state('interview-folder', {
            parent: 'app',
            url: '/interview/album',
            data: {
                authorities: [],
                pageTitle: 'global.menu.gallery'
            },
            views: {
                'content@': {
                    templateUrl: 'app/interview/interview-folders.html',
                    controller: 'InterviewFolderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('news');
                    return $translate.refresh();
                }]
            }
        })
        .state('interview-view', {
            parent: 'interview',
            url: '/interview-view/{filename}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/interview/interview-view.html',
                    controller: 'InterviewViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    keyboard: false,
                    resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('news');
                        return $translate.refresh();
                    }]
                    }
                }).result.then(function() {
                    $state.go('interview', null, {});
                }, function() {
                    $state.go('interview');
                });
            }]
        });
    }
})();
