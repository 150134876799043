(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('ViewComplaintDetailController', ViewComplaintDetailController);

    ViewComplaintDetailController.$inject = ['$scope', 'entity', 'ComplaintDownloadPdf', '$timeout'];

    function ViewComplaintDetailController($scope,entity, ComplaintDownloadPdf, $timeout) {
        var vm = this;
        vm.complaintForm=entity;
        vm.afilename=null;
        vm.pcfilename=null;
        vm.ccfilename=null;
        vm.complaintFormPdf=complaintFormPdf;
        vm.printToCart=printToCart;
        vm.asVictim="No";
        if(vm.complaintForm.complainantAsVictim){
          vm.asVictim="Yes";
        }else{vm.asVictim="No";}

        function printToCart(printSectionId) {
            var innerContents = document.getElementById(printSectionId).innerHTML;
            var popupWinindow = window.open('', '_blank', 'width=1100,height=700');
//            var popupWinindow;
            popupWinindow.document.open();
            popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
            $timeout(function() {
            popupWinindow.document.close();
            },100);
        }

        if(vm.complaintForm.attachedFileName!=null)
        {
            vm.afilename=vm.complaintForm.attachedFileName+"-"+vm.complaintForm.cName+"_OtherAttachment";
        }
        if(vm.complaintForm.policeComplaintFileName!=null)
        {
            vm.pcfilename=vm.complaintForm.policeComplaintFileName+"-"+vm.complaintForm.cName+"_PoliceComplaint";
        }
        if(vm.complaintForm.caseInCourtFileName!=null)
        {
            vm.ccfilename=vm.complaintForm.caseInCourtFileName+"-"+vm.complaintForm.cName+"_CaseFiledInCourt";
        }

        function complaintFormPdf(){
            ComplaintDownloadPdf.download(vm.complaintForm.id);
        }


    }
})();
