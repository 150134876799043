(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('pic-gallery', {
            parent: 'app',
            url: '/pic-gallery/album/{caption}',
            data: {
                authorities: [],
                pageTitle: 'global.menu.gallery'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pics-gallery/pic-gallery.html',
                    controller: 'PicGalleryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pic-gallery');
                    return $translate.refresh();
                }]
            }
        })
            .state('pic-gallery-folder', {
                parent: 'app',
                url: '/pic-gallery/album',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.gallery'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/pics-gallery/pic-gallery-folders.html',
                        controller: 'PicGalleryFolderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pic-gallery');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pic-gallery-view', {
                parent: 'pic-gallery',
                url: '/pic-gallery-view/{filename}',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/pics-gallery/pic-gallery-view.html',
                        controller: 'PicGalleryViewController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        keyboard: false,
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('pic-gallery');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('pic-gallery', null, {});
                    }, function() {
                        $state.go('pic-gallery');
                    });
                }]
            });
    }
})();
