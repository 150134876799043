(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('ComplaintInstructionController', ComplaintInstructionController);

    ComplaintInstructionController.$inject = ['$scope','$state','$uibModalInstance'];

    function ComplaintInstructionController($scope,$state,$uibModalInstance) {
        var vm = this;
        vm.clear=clear;
        vm.agree=agree;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function agree () {
            $state.go('file-complaints');
            $uibModalInstance.close();
        }
    }
})();
