(function() {
    'use strict';

    angular
        .module('womenCellApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('file-complaints', {
            parent: 'app',
            url: '/file-complaints',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/file-complaints/file-complaints.html',
                    controller: 'FileComplaintsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('file-complaints');
                    return $translate.refresh();
                }]
            }
        })
        .state('complaintInstruction', {
            parent: 'file-complaints',
            url: '/complaintInstruction',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/file-complaints/complaintInstruction.html',
                    controller: 'ComplaintInstructionController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    keyboard: false,
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('file-complaints', null, { reload: 'file-complaints' });
                }, function() {
                    $state.go('home');
                });
            }]
        });
    }
})();
