(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('FileComplaintsController', FileComplaintsController);

    FileComplaintsController.$inject = ['$scope','ComplaintForm','FileSave', 'CheckContent'];

    function FileComplaintsController($scope,ComplaintForm,FileSave, CheckContent) {

        var vm = this;
        vm.success = null;
        vm.error = null;
        vm.afFile=null;
        vm.pfFile=null;
        vm.cfFile=null;
        vm.save=save;
        vm.complainantByVictim=complainantByVictim;
        vm.saveEntity=saveEntity;
        vm.pfUpload=pfUpload;
        vm.cfUpload=cfUpload;
        vm.expectedActionFromCommission = ["Counseling","Compromise","Help Through Police","Free Legal Help","Other"];
        vm.district= ["Ambala","Bhiwani","Charkhi Dadri","Faridabad","Fatehabad","Gurugram","Hisar","Jhajjar","Jind","Kaithal","Karnal",
                    "Kurukshetra","Mahendragarh","Mewat","Palwal","Panchkula","Panipat","Rewari","Rohtak","Sirsa","Sonipat","Yamunanagar"];
        vm.sex = ["Male","Female","Transgender","Unknown","Not Mentioned"];
        vm.complaint = ["Acid Attack","Attempt to Murder","Attempt to Rape","Bigamy/polygamy","Complaints by In Laws","Cyber Crime Against Women","Deprivation of Property Rights",
            "Deprivation of Women Rights","Divorce","Dowry Death","Dowry Harassment/Dowry Death","Free Legal Aid for Women","Gender Discrimination including equal right to education &amp; work",
            "Harassment at Workplace","Indecent Representation of Women","Kidnapping/Abduction","Maintenance Claim","Matter involving Custody of Children","Miscellaneous","Murder","Outraging Modesty of Women",
            "Police Apathy against Women","Pre Marital Breach of Trust","Privacy of women and rights thereof","Rape","Reproductive health rights of women","Right to exercise choice in marriage","Right to live with dignity",
            "Sex Scandals","Sex selective abortion/female foeticide/amniocentesis","Sexual harassment including sexual harassment at workplace","Stalking/Voyeurism","Suicide","Trafficking/Prostitution of women",
            "Violence against women", "Honey Trap/Extortion Matter"];
        vm.casvictim = {'true': 'Yes', 'false': 'No'};
        vm.religion =["Hindu","Muslim","Sikh","Christian","Others","Unknown"];
        vm.caste =["General","SC","ST","OBC","Unknown","Not Mentioned"];

        function save(){
            if(vm.afFile!=null) afUpload();
            else if(vm.pfFile!=null) pfUpload();
            else if(vm.cfFile!=null) cfUpload();
            else saveEntity();
        }

        function afUpload() {
            if(CheckContent.checkFile(vm.afFile.name)){
                var file=vm.afFile;
                FileSave.upload(file, function(result){
                    vm.complaintForm.attachedFileName=result.filename;
                    if(vm.pfFile!=null) pfUpload();
                    else if(vm.cfFile!=null) cfUpload();
                    else saveEntity();
                });
            }else alert('Invalid Attachment File');
        }

        function pfUpload(){
            if(CheckContent.checkFile(vm.pfFile.name)){
                var file=vm.pfFile;
                FileSave.upload(file, function(result){
                    vm.complaintForm.policeComplaintFileName=result.filename;
                    if(vm.cfFile!=null) cfUpload();
                    else saveEntity();
                });
            }else alert('Invalid Police Complaint File');
        }

        function cfUpload(){
            if(CheckContent.checkFile(vm.cfFile.name)){
                var file=vm.cfFile;
                FileSave.upload(file, function(result){
                    vm.complaintForm.caseInCourtFileName=result.filename;
                    saveEntity();
                });
            }else alert('Invalid Case filed in Court File');
        }

        function saveEntity () {
            if(vm.complaintForm.complaintType=='Any Other'){
                vm.complaintForm.complaintType=vm.complaintForm.other;
            }else{
                vm.complaintForm.complaintType=vm.complaintForm.complaintType;
            }
            ComplaintForm.save(vm.complaintForm, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.success = 'OK';
        }

        function onSaveError () {
            vm.error = 'ERROR';
        }

        function complainantByVictim(){
            vm.complaintForm.vName=vm.complaintForm.cName;
            vm.complaintForm.vSex=vm.complaintForm.cSex;
            vm.complaintForm.vAddress=vm.complaintForm.cAddress;
            vm.complaintForm.vPin=vm.complaintForm.cPin;
            vm.complaintForm.vMobile=vm.complaintForm.cMobile;
            vm.complaintForm.vLandline=vm.complaintForm.cLandline;
            vm.complaintForm.vEmail=vm.complaintForm.cEmail;
        }

        $('input').each(function(){ CheckContent.checkInput($(this))});
        $('textarea').each(function() { CheckContent.checkInput($(this))});
    }
})();
