(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('NewsController', NewsController);

    NewsController.$inject = ['$scope', '$sce','ImgSave','Gallery','ImgDel', 'CheckContent'];

      function NewsController($scope, $sce, ImgSave, Gallery,ImgDel, CheckContent) {
          var vm = this;
          vm.upload=upload;
          vm.gallery={};
          vm.gallery.fileType="Image";
          vm.filenameList=[];
          vm.removeImg=removeImg;
          vm.del=false;
          vm.delIcon=delIcon;
          $scope.selection=[];

          Gallery.query({id:"all",type:"news"},function(result){
              vm.newsList=result;
              if(vm.newsList.length==0)
              {
                  $scope.nofile=true;
              }else $scope.nofile=false;
          });

          function delIcon()
          {
            if(vm.del)
            {
                vm.del=false;
            }else vm.del=true;
          }

          function upload(){
            if(vm.image==null){
                saveEntity("Video")
            }
            else{
                if(vm.image.length>1) {
                    for(var i=0;i<vm.image.length;i++) {
                        if(CheckContent.checkFile(vm.image[i].name)){
                            ImgSave.upload(vm.image[i], function(result){
                                saveEntity(result);
                            });
                        }else alert('Invalid file format!\n' +
                            'अमान्य फ़ाइल प्रकार!');
                    }
                }
                else{
                    if(CheckContent.checkFile(vm.image.name)){
                        ImgSave.upload(vm.image, function(result){
                            saveEntity(result);
                        });
                    }else alert('Invalid file format!\n' +
                        'अमान्य फ़ाइल प्रकार!');
                }
            }
          }
          function saveEntity(result) {
              if(result==="Video"){
                  vm.gallery.filename=vm.filename;
              }
              else {
                  vm.gallery.filename=result.filename;
              }
              vm.gallery.type="news";
              Gallery.save(vm.gallery,onSaveSuccess,onSaveError);
          }
          function onSaveSuccess()
          {
              alert("Successfully added file!");
              location.reload();

          }
          function onSaveError()
          {
              alert("Error while adding file. Please try again after some time!");
              location.reload();
          }

          $scope.toggleSelection=function(twoValue){
              var index=$scope.selection.indexOf(twoValue);
              if(index > -1)
              {
                  $scope.selection.splice(index,1);
              }else{
                 $scope.selection.push(twoValue);
              }
          }
          function removeImg()
          {
             for(var i=0;i<$scope.selection.length;i++)
             {
               var id=$scope.selection[i].id;
               var filename=$scope.selection[i].filename;
               confDel($scope.selection[i].fileType,id,filename);
             }
          }
          function confDel(type,id,filename)
          {
            if(type==="Image")
           {
             ImgDel.delete({filename: filename},function(){
                Gallery.delete({id: id},onDelSuccess,onDelError);
             });
           }else{
                Gallery.delete({id: id},onDelSuccess,onDelError);
           }
          }
          function onDelSuccess()
          {
              alert("File is deleted successfully!");
              location.reload();
          }
          function onDelError()
          {
              alert("Error while deleting the file. Please try again after some time!");
              location.reload();
          }
          vm.getImageUrl=function (image) {
              if(image.fileType==="Video"){
                  return $sce.trustAsResourceUrl("https://www.youtube.com/embed/"+image.filename);
              }
          }

      }
    })();
