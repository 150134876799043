(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('ViewComplaintController', ViewComplaintController);

    ViewComplaintController.$inject = ['$scope', 'ComplaintForm'];

    function ViewComplaintController($scope,ComplaintForm) {
        var vm = this;
        vm.complaintForms = [];
        loadAll();

        function loadAll() {
            ComplaintForm.query(function(result) {
                vm.complaintForms = result;
            });
        }
    }
})();
