(function () {
    'use strict';

    angular
        .module('womenCellApp')
        .controller('FAQsController', FAQsController);

    FAQsController.$inject = ['$scope'];

    function FAQsController($scope) {
        var vm = this;

    }
})();
